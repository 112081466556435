import React from 'react';
// import Swiper core and required components
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles.
// Importing styles this way did not work with the Gatsby build process.
// import 'swiper/swiper.scss';
// import 'swiper/components/pagination/pagination.scss';

// Install Swiper components
SwiperCore.use([
  Autoplay,
  Pagination,
]);

const Carousel = ({ slides, paginationOverrides, paginationClassOverride }) => {
  const swiperOptions = {
    slidesPerView: 1,
    /*
    autoplay: {
      delay: 5000,
    },
    */
    loop: true,
  }
  let paginationClass = 'swiper-pagination';

  if (slides.length > 1) {
    let optionsPagination = {
      el: `.${paginationClass}`,
      clickable: true,
    }
    if (paginationClassOverride) {
      optionsPagination.el = `.${paginationClassOverride}`;
      paginationClass = paginationClassOverride;
    }

    swiperOptions.pagination = {...optionsPagination, ...paginationOverrides};
  }

  const renderPagination = () => {
    if (slides.length < 2) {
      return null;
    }
    return <div className={paginationClass}></div>;
  }

  return (
    <Swiper
      {...swiperOptions}
    >
      {slides.map(slide => {
        return <SwiperSlide key={slide.key}>{slide}</SwiperSlide>;
      })}

      {renderPagination()}
    </Swiper>
  );
}

export default Carousel;
