import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import Container from '~components/container/container.component';
import Carousel from '~components/Carousel';

import styles from './hero.module.scss';

const HeroSlide = props => {
  const { slide, isExternal } = props;
  let body;
  if (isExternal) {
    body = slide.body;
  }
  else {
    body = slide.childContentfulCtaBlockBodyTextNode
      ? slide.childContentfulCtaBlockBodyTextNode.body
      : slide.body
        ? slide.body.body
        : '';
  }
  const {
    title,
    ctaText,
    ctaUrl,
    linkText,
    resourceFile,
    resourceUrl,
  } = slide;

  const renderSlideBtn = () => {
    if (ctaUrl && ctaText) {
      return <Link className={styles.heroSlideCtaBtn} to={`/${ctaUrl}`}>{ctaText}</Link>;
    }
    else if (linkText && (resourceFile || resourceUrl)) {
      const srcUrl = resourceUrl
        ? resourceUrl
        : resourceFile
          ? isExternal ? resourceFile.fields.file.url : resourceFile.file.url
          : null;

      if (srcUrl) {
        return (
          <a
            className={styles.heroSlideCtaBtn}
            href={srcUrl}
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        );
      }
    }
    return null;
  }

  return (
    <div className={styles.heroSlide}>
      <h3 className={styles.heroSlideHeader}>{title}</h3>
      <div className={styles.heroSlideCopy}>
        <ReactMarkdown source={body} />
      </div>
      {renderSlideBtn()}
    </div>
  )
}

const HeroSlides = props => {
  const { slides, isExternal } = props;
  const renderSlides = () => {
    return slides.map((slide, i) => {
      return (
        <HeroSlide
          key={i}
          slide={slide}
          isExternal={isExternal}
        />
      );
    });
  }

  return (
    <div className={styles.heroSlidesWrapper}>
      <Carousel
        slides={renderSlides()}
        paginationOverrides={{
          bulletClass: styles.heroSwiperPaginationBullet,
          bulletActiveClass: `swiper-pagination-bullet-active ${styles.heroSwiperPaginationBulletActive}`,
        }}
        paginationClassOverride={styles.heroSwiperPagination}
      />
    </div>
  );
}

const Hero = props => {
  const { isExternal } = props;
  const {
    bannerCopy,
    image,
    bynderBackgroundImage,
    slides,
  } = props.data;

  let imageObj;
  let slidesArray = [];
  if (bynderBackgroundImage && bynderBackgroundImage.length) {
    imageObj = {
      file: {url: bynderBackgroundImage[0].original},
      //description: bynderBackgroundImage[0].description ? bynderBackgroundImage[0].description : bynderBackgroundImage[0].name,
    };
  }
  else if (isExternal) {
    imageObj = image ? image.fields : null;
  }
  else {
    imageObj = image;
  }

  if (isExternal) {
    slidesArray = slides ? slides.map(slide => slide.fields) : null;
  }
  else {
    slidesArray = slides;
  }

  const renderBgImg = () => {
    if (!imageObj) {
      return null;
    }
    return (
      <div className={styles.heroBgImgWrapper}>
        <img
          className={styles.heroBgImg}
          src={imageObj.file.url}
          alt={imageObj.description}
        />
      </div>
    );
  }

  const renderBannerCopy = () => {
    if (!bannerCopy) {
      return null;
    }

    let classes = [
      styles.heroBannerCopy,
    ];

    if (!imageObj) {
      classes.push(styles.heroBannerCopyNoBgImg);
    }


    return (
      <h1 className={classes.join(' ')} >
        <span className={styles.heroBannerCopy__inner} dangerouslySetInnerHTML={{__html: bannerCopy}}>
          
        </span>
      </h1>
      
    );
  }

  const renderSlides = () => {
    if (!slidesArray || !slidesArray.length) {
      return null;
    }
    return (
      <HeroSlides
        slides={slidesArray}
        isExternal={isExternal}
      />
    );
  }

  let wrapperClasses = [
    styles.hero,
  ];

  if (!imageObj) {
    wrapperClasses.push(styles.heroNoBgImg);
  }

  return (
    <section className={wrapperClasses.join(' ')}>
      <Container>
        {renderBgImg()}
        {renderBannerCopy()}
        {renderSlides()}
      </Container>
    </section>
  );
}

export default Hero;
