import React, { Component } from 'react';
import Modal from '~components/modal/modal.component';
import styles from './iframe.module.scss';

class IFrame extends Component {
  state = {
    showModal: false,
  }

  handleToogleShowModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  renderBtn = () => {
    const { handleToogleShowModal } = this;
    const { children, btnClass } = this.props;
    return (
      <button
        onClick={handleToogleShowModal}
        className={btnClass}
        style={{cursor: 'pointer'}}
      >
        {children}
      </button>
    );
  }

  render() {
    const { handleToogleShowModal, renderBtn } = this;
    const { showModal } = this.state;
    const { url, title } = this.props;

    return (
      <>
        {renderBtn()}
        <Modal
          showModal={showModal}
          overrideProps={{
            shouldCloseOnOverlayClick: true,
            className: styles.content,
            overlayClassName: {
              base: styles.ReactModal__Overlay,
              afterOpen: styles.ReactModal__OverlayAfterOpen,
              beforeClose: styles.ReactModal__OverlayBeforeClose,
            }
          }}
        >
          <div className={styles.close}>
            <button
              onClick={handleToogleShowModal}
            >
              <span className={styles.srText}>Close Modal</span>
              <i className="fas fa-times" />
            </button>
          </div>
          <iframe
            className={styles.iframe}
            title={title}
            src={url}
            scrolling="no"
            frameBorder="0"
            marginHeight="0px"
            marginWidth="0px"
          />
        </Modal>
      </>
    );
  }
}

export default IFrame;
