import React from 'react';
import Img from 'gatsby-image';
import RenderMarkdown from '~components/RenderMarkdown';
import IFrame from '~components/modal/iframe/iframe.component';

import styles from './resource-card.module.scss';

const ResourceCard = (props) => {
  const {
    image,
    bynderImage,
    title,
    body,
    linkText,
    resourceFile,
    resourceUrl,
    iFrame,
    secured = false,
  } = props;

  const renderImg = () => {
    if (!image && !bynderImage) {
      return null;
    }

    if (bynderImage && bynderImage.length) {
      const {
        description,
        name,
        src,
      } = bynderImage[0];
      return (
        <img
          className={styles.cardImageResponsive}
          alt={description ? description : name}
          src={src}
        />
      );
    }

    if (secured) {
      const {
        description,
        file: { url },
      } = image.fields;

      return (
        <img
          className={styles.cardImageResponsive}
          alt={description}
          src={`${url}?fm=jpg&fl=progressive&w=550&fit=scale`}
        />
      );
    }

    const {
      description,
      fluid,
    } = image;

    return (
      <Img
        alt={description}
        fluid={fluid}
      />
    );
  }

  const renderBody = () => {
    if (!body) {
      return null;
    }

    const text = secured ? body : body.body;

    return (
      <div className={styles.cardBody}>
        <RenderMarkdown text={text} />
      </div>
    );
  }

  const renderBtn = () => {
    if (!linkText) {
      return null;
    }
    const srcUrl = iFrame
      ? iFrame
      : resourceUrl
        ? resourceUrl
        : resourceFile
          ? secured ? resourceFile.fields.file.url : resourceFile.file.url
          : null;

    if (srcUrl && iFrame) {
      return (
        <IFrame
          url={srcUrl}
          btnClass={styles.btn}
          title={linkText}
        >
          {linkText}
        </IFrame>
      );
    }
    else if (srcUrl && resourceFile) {
      return (
        <a
          className={styles.btn}
          href={srcUrl}
          target="_blank"
          rel="noreferrer"
        >
          {linkText}
        </a>
      );
    }
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={`${styles.cardImage} hidden-mobile`}>
        {renderImg()}
      </div>
      <div className={styles.cardContent}>
        <h2 className={styles.cardTitle}>
          {title}
        </h2>
        {renderBody()}
        {renderBtn()}
      </div>
    </div>
  );
}

export default ResourceCard;
